import { Box, Typography, Button } from '@mui/material';
import React from 'react';
import ErrorLayout from '../../../layouts/ErrorLayout';
import Link from '../../../components/Link';
import SvceButton from '../../../components/SvceButton';

const StyledLink = ({ href, children, withBorder = true }) => (
  <Link
    href={href}
    sx={{
      px: 5,
      mb: { xs: 1.5, sm: 0 },
      borderRight: { xs: 'none', sm: withBorder && `1px solid` },
      borderColor: { xs: 'auto', sm: withBorder && 'gray.300' },
      color: '#07679b',
      textDecoration: 'none',
      fontWeight: 400,
      fontSize: '1.25rem',
    }}
  >
    {children}
  </Link>
);

function ErrorPage({ error_code: errorCode, error_message: errorMessage }) {
  return (
    <Box
      px={2}
      py={12}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      maxWidth={1000}
      m="0 auto"
    >
      <Typography
        component="h1"
        display="flex"
        flexDirection="column"
        alignItems="center"
        textAlign="center"
        mb={5}
      >
        {errorCode && (
          <Typography
            component="span"
            variant="h4"
            fontWeight={700}
            fontSize="1.125rem"
            textTransform="uppercase"
            mb={2}
          >
            {errorCode} error
          </Typography>
        )}
        <Typography
          component="span"
          variant="h1"
          fontWeight={700}
          fontSize={{ xs: '1.875rem', md: '2.75rem', lg: '3.75rem' }}
        >
          {errorMessage}
        </Typography>
      </Typography>

      <SvceButton
        onClick={() => window.history.back()}
        sx={{
          py: 2,
          px: 8,
          mb: 5,
          fontSize: '1rem',
        }}
      >
        Go back
      </SvceButton>

      <Typography component="p" variant="h3" mb={5} fontSize="1.5rem">
        Here are some helpful links
      </Typography>
      <Box
        display="flex"
        flexDirection={{ xs: 'column', sm: 'row' }}
        alignItems="center"
        justifyContent="center"
      >
        <StyledLink href="/">Home</StyledLink>
        <StyledLink href="/incentives">Recommendations</StyledLink>
        <StyledLink href="/products" withBorder={false}>
          Appliances Marketplace
        </StyledLink>
      </Box>
    </Box>
  );
}

ErrorPage.layout = ErrorLayout;

export default ErrorPage;
