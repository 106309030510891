import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import HeadingWithFlair from '../../../components/HeadingWithFlair';
import ButtonLink from '../../../components/ButtonLink';

export default function ApplianceTab({
  heading,
  img,
  imgAlt,
  testimonial,
  testimonialName,
  testimonialImg,
  buttonLink,
  buttonText = 'Appliances Marketplace',
  showProductsButton = true,
  children,
}) {
  return (
    <Box>
      <Grid container spacing={7}>
        <Grid xs={12} md={7} item sx={{ fontSize: '1.125rem', color: 'common.white' }}>
          <HeadingWithFlair sx={{ fontSize: { xs: '2rem', md: '2.5rem' } }}>
            {heading}
          </HeadingWithFlair>
          <Box pb={2} borderBottom="1px solid" borderColor="common.white">
            {children}
          </Box>

          {testimonial && (
            <Box pt={2}>
              <Typography fontStyle="italic">{testimonial}</Typography>
            </Box>
          )}

          {testimonialImg && testimonialName && (
            <Box display="flex" alignItems="center" mt={2}>
              <Box
                component="img"
                src={testimonialImg}
                alt=""
                height={45}
                width={45}
                borderRadius="50%"
                mr={2}
                sx={{ objectFit: 'cover' }}
              />
              <Typography fontWeight={700} fontStyle="italic">
                {testimonialName}
              </Typography>
            </Box>
          )}

          {showProductsButton && (
            <ButtonLink
              href={buttonLink || '/products'}
              sx={{ display: 'inline-block', width: { xs: '100%', md: 'auto' }, mt: 2 }}
            >
              {buttonText}
            </ButtonLink>
          )}
        </Grid>
        <Grid xs={0} md={5} item display={{ xs: 'none', md: 'flex' }}>
          <Box
            height="100%"
            sx={{
              borderRadius: '30px',
              border: '10px solid',
              borderColor: 'primary.main',
              overflow: 'hidden',
              display: { xs: 'none', md: 'block' },
            }}
          >
            <Box
              component="img"
              height="100%"
              width="100%"
              src={img}
              alt={imgAlt}
              sx={{ objectFit: 'cover' }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
