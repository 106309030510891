import {
  Container,
  Typography,
  Table as MuiTable,
  TableBody,
  TableRow,
  TableHead,
  TableCell,
  Button,
  Collapse,
  Box,
} from '@mui/material';
import React, { useState } from 'react';
import { CheckboxIcon, DownArrowIcon } from '../../../assets/icons/icons';
import ButtonLink from '../../../components/ButtonLink';

const CheckboxItem = ({ children, borderRight = false }) => (
  <TableCell
    sx={{
      borderLeft: '1px solid',
      borderRight: borderRight ? '1px solid' : 'none',
      borderColor: 'gray.300',
    }}
  >
    <Box display="flex" alignItems="center">
      <Box flexShrink={0}>
        <CheckboxIcon />
      </Box>
      <Typography ml={3}>{children}</Typography>
    </Box>
  </TableCell>
);

export default function Table() {
  const [isBenefitsExpanded, setIsBenefitsExpanded] = useState(false);
  const [isUpgradesExpanded, setIsUpgradesExpanded] = useState(false);

  return (
    <Box sx={{ mb: 7, pt: 5, pb: 7 }} bgcolor="svce.darkBlue">
      <Container maxWidth="lg">
        <Typography variant="h2" fontWeight={600} fontSize="2rem" mb={3} color="common.white">
          Three pathways to an electric home
        </Typography>
        <Box sx={{ overflowX: 'scroll' }}>
          <MuiTable sx={{ tableLayout: 'fixed', minWidth: 800, bgcolor: 'common.white' }}>
            <TableHead>
              <TableRow
                sx={{
                  bgcolor: 'primary.main',
                }}
              >
                <TableCell
                  align="center"
                  sx={{
                    color: 'common.white',
                    fontWeight: 600,
                    fontSize: '1.125rem',
                    borderBottom: 'none',
                  }}
                >
                  Rebates
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    color: 'common.white',
                    fontWeight: 600,
                    fontSize: '1.125rem',
                    borderBottom: 'none',
                  }}
                >
                  Assisted
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    color: 'common.white',
                    fontWeight: 600,
                    fontSize: '1.125rem',
                    borderBottom: 'none',
                  }}
                >
                  Full-Service
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="center" sx={{ fontWeight: 600, px: 5, fontSize: '1rem' }}>
                  Choose your ideal contractor, reserve your rebate, and claim your reimbursement.
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: 600, px: 5, fontSize: '1rem' }}>
                  Maximize your savings with our web-enabled platform that assists with incentives
                  and competitive quotes
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: 600, px: 5, fontSize: '1rem' }}>
                  Enjoy hassle-free upgrades with fixed pricing and the help of our trusted partner
                  contractors.
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell sx={{ borderLeft: '1px solid', borderColor: 'gray.300' }}>
                  <ButtonLink href="https://svcleanenergy.org/home-rebates/" external>
                    Learn More
                  </ButtonLink>
                </TableCell>
                <TableCell sx={{ borderLeft: '1px solid', borderColor: 'gray.300' }}>
                  <ButtonLink href="https://goelectric.svcleanenergy.org/" external>
                    Learn More
                  </ButtonLink>
                </TableCell>
                <TableCell
                  sx={{
                    borderLeft: '1px solid',
                    borderRight: '1px solid',
                    borderColor: 'gray.300',
                  }}
                >
                  <ButtonLink href="https://svcleanenergy.org/offers-services/" external>
                    Learn More
                  </ButtonLink>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan={3} align="center" sx={{ p: 0 }}>
                  <Button
                    onClick={() => setIsBenefitsExpanded((prev) => !prev)}
                    sx={{
                      width: '100%',
                      display: 'block',
                      py: '1.125rem',
                      bgcolor: 'primary.main',
                      borderRadius: 0,
                      textTransform: 'none',
                      border: 'none',
                      '&:hover': {
                        bgcolor: 'primary.main',
                        opacity: 0.9,
                      },
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        position: 'sticky',
                        left: '50%',
                        alignSelf: 'center',
                        transform: 'translateX(-50%)',
                        textAlign: 'center',
                        width: { xs: '140px' },
                      }}
                    >
                      <Typography
                        sx={{ color: 'common.white', fontSize: '1.125rem', fontWeight: 'bold' }}
                      >
                        See Benefits
                      </Typography>
                      <Box ml={1} sx={{ transform: `rotate(${isBenefitsExpanded ? 180 : 0}deg)` }}>
                        <DownArrowIcon fill="#FFF" />
                      </Box>
                    </Box>
                  </Button>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan={3} sx={{ borderBottom: 'none', p: 0 }}>
                  <Collapse in={isBenefitsExpanded} timeout="auto" unmountOnExit>
                    <MuiTable sx={{ tableLayout: 'fixed' }}>
                      <TableHead>
                        <TableRow sx={{ position: 'absolute', top: -9999, left: -9999 }}>
                          <TableCell>Benefits for Rebates tier</TableCell>
                          <TableCell>Benefits for Assisted tier</TableCell>
                          <TableCell>Benefits for Full-Service tier</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <CheckboxItem>Simple Process</CheckboxItem>
                          <CheckboxItem>Includes SVCE Rebates</CheckboxItem>
                          <CheckboxItem borderRight>Includes SVCE Rebates</CheckboxItem>
                        </TableRow>
                        <TableRow>
                          <CheckboxItem>Stacks with Other Incentives</CheckboxItem>
                          <CheckboxItem>Stacks with Other Incentives</CheckboxItem>
                          <CheckboxItem borderRight>Stacks with Other Incentives</CheckboxItem>
                        </TableRow>
                        <TableRow>
                          <CheckboxItem>Use Own Contractor</CheckboxItem>
                          <CheckboxItem>Quotes Provided</CheckboxItem>
                          <CheckboxItem borderRight>Fixed Pricing</CheckboxItem>
                        </TableRow>
                        <TableRow>
                          <CheckboxItem>DIY Install Option</CheckboxItem>
                          <CheckboxItem>Rebate Application Support</CheckboxItem>
                          <CheckboxItem borderRight>Dedicated Project Manager</CheckboxItem>
                        </TableRow>
                        <TableRow>
                          <TableCell sx={{ borderLeft: '1px solid', borderColor: 'gray.300' }} />
                          <TableCell sx={{ borderLeft: '1px solid', borderColor: 'gray.300' }} />
                          <CheckboxItem borderRight>Installation w/Partner Contractor</CheckboxItem>
                        </TableRow>
                      </TableBody>
                    </MuiTable>
                  </Collapse>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan={3} align="center" sx={{ p: 0 }}>
                  <Button
                    onClick={() => setIsUpgradesExpanded((prev) => !prev)}
                    sx={{
                      width: '100%',
                      display: 'block',
                      py: '1.125rem',
                      bgcolor: 'primary.main',
                      borderRadius: 0,
                      textTransform: 'none',
                      border: 'none',
                      '&:hover': {
                        bgcolor: 'primary.main',
                        opacity: 0.9,
                      },
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        position: 'sticky',
                        left: '50%',
                        alignSelf: 'center',
                        transform: 'translateX(-50%)',
                        textAlign: 'center',
                        width: { xs: 155, sm: 215 },
                      }}
                    >
                      <Typography
                        sx={{ color: 'common.white', fontSize: '1.125rem', fontWeight: 'bold' }}
                      >
                        See Covered Upgrades
                      </Typography>
                      <Box ml={1} sx={{ transform: `rotate(${isUpgradesExpanded ? 180 : 0}deg)` }}>
                        <DownArrowIcon fill="#FFF" />
                      </Box>
                    </Box>
                  </Button>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan={3} sx={{ borderBottom: 'none', p: 0 }}>
                  <Collapse in={isUpgradesExpanded} timeout="auto" unmountOnExit>
                    <MuiTable sx={{ tableLayout: 'fixed' }}>
                      <TableHead>
                        <TableRow sx={{ position: 'absolute', top: -9999, left: -9999 }}>
                          <TableCell>Covered Upgrades for Rebates tier</TableCell>
                          <TableCell>Covered Upgrades for Assisted tier</TableCell>
                          <TableCell>Covered Upgrades for Full-Service tier</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <CheckboxItem>Heat Pump Heating/Cooling</CheckboxItem>
                          <CheckboxItem>Heat Pump Heating/Cooling</CheckboxItem>
                          <CheckboxItem borderRight>Heat Pump Heating/Cooling</CheckboxItem>
                        </TableRow>
                        <TableRow>
                          <CheckboxItem>Heat Pump Water Heater</CheckboxItem>
                          <CheckboxItem>Heat Pump Water Heater</CheckboxItem>
                          <CheckboxItem borderRight>Heat Pump Water Heater</CheckboxItem>
                        </TableRow>
                        <TableRow>
                          <CheckboxItem>Induction Stove</CheckboxItem>
                          <CheckboxItem>Induction Stove</CheckboxItem>
                          <CheckboxItem borderRight>Induction Stove</CheckboxItem>
                        </TableRow>
                        <TableRow>
                          <CheckboxItem>Electric Infrastructure Upgrades</CheckboxItem>
                          <CheckboxItem>Electric Infrastructure Upgrades</CheckboxItem>
                          <CheckboxItem borderRight>Electric Infrastructure Upgrades</CheckboxItem>
                        </TableRow>
                        <TableRow>
                          <TableCell sx={{ borderLeft: '1px solid', borderColor: 'gray.300' }} />
                          <TableCell sx={{ borderLeft: '1px solid', borderColor: 'gray.300' }} />
                          <CheckboxItem borderRight>Electric Dryer</CheckboxItem>
                        </TableRow>
                        <TableRow>
                          <TableCell sx={{ borderLeft: '1px solid', borderColor: 'gray.300' }} />
                          <TableCell sx={{ borderLeft: '1px solid', borderColor: 'gray.300' }} />
                          <CheckboxItem borderRight>Efficiency Upgrades</CheckboxItem>
                        </TableRow>
                      </TableBody>
                    </MuiTable>
                  </Collapse>
                </TableCell>
              </TableRow>
            </TableBody>
          </MuiTable>
        </Box>
      </Container>
    </Box>
  );
}
