import { Typography } from '@mui/material';
import { usePage } from '@inertiajs/react';
import ApplianceTab from './ApplianceTab';
import Link from '../../../components/Link';

export default function SmartThermostat() {
  const {
    props: { thermostat_image: thermostatImage },
  } = usePage();

  return (
    <ApplianceTab
      heading="Smart Thermostat"
      img={thermostatImage}
      imgAlt="Image of a smart thermostat"
      buttonLink="/products/thermostats"
      buttonText="Shop Smart Thermostats"
    >
      <Typography mb={2}>
        A smart thermostat is a cost-effective way to save on heating and cooling costs. It learns
        your routines, lets you control the temperature from your smartphone, and automatically
        adjusts to save energy when you're away. Forget to turn off the thermostat? Your smart
        thermostat can even be turned off with an app.
      </Typography>
      <Typography>
        Learn more about smart thermostats with{' '}
        <Link
          href="https://svcleanenergy.org/wp-content/uploads/2020/02/Smart_Thermostat_Basics.pdf"
          sx={{ color: 'green.400' }}
          external
        >
          The Basics
        </Link>
        . You can also shop for one and find incentives on the Appliances Marketplace.
      </Typography>
    </ApplianceTab>
  );
}
